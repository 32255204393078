import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../config/i18n";


// Assets
import rubi from "../assets/image/rubi.svg";
import googleplay from "../assets/image/googleplay.svg";
import appstore from "../assets/image/appstore.svg";
import vectorPurple from "../assets/image/vector_purple.svg";
import vectorGreen from "../assets/image/vector_green.svg";


// Google Analytics
import ReactGA from "react-ga4";

const TRACKING_ID = "G-E1MM32LPMP";
ReactGA.initialize(TRACKING_ID);

export default function Home() {
  const { t } = useTranslation();

  // Envoie un événement de pageview lorsqu'un utilisateur arrive sur la page
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  // Fonction pour analyser les clics
  const trackLinkClick = (linkName, linkType) => {
    ReactGA.event({
      category: linkType, // Catégorie du lien (App Links, Footer Links, etc.)
      action: `Clicked on ${linkName}`, // Action déclenchée
      label: `Link: ${linkName}`, // Libellé pour identifier le lien
    });
  };

  return (
    <div className="App">
      <img src={vectorPurple} alt="Vector Purple" className="vector" />
      <img src={vectorGreen} alt="Vector Green" className="vector" />
      <div className="logo-container">
        <img src={rubi} alt="Logo" className="logo" />
      </div>
      <div className="app-links">
        <a
          href="https://play.google.com/store/apps/details?id=com.rubi.app"
          className="app-link"
          onClick={() => trackLinkClick("Google Play", "App Links")}
        >
          <img src={googleplay} alt="Google Play" className="store-app" />
        </a>
        <a
          href="https://apps.apple.com/app/id6720740387"
          className="app-link"
          onClick={() => trackLinkClick("App Store", "App Links")}
        >
          <img src={appstore} alt="App Store" className="store-app" />
        </a>
      </div>
      <div className="footer-links">
        <a
          href="/legal-terms"
          rel="noopener noreferrer"
          onClick={() => trackLinkClick("Legal Terms", "Footer Links")}
        >
          {t("home.legal_terms")}
        </a>
        <a
          href="/privacy"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackLinkClick("Privacy Policy", "Footer Links")}
        >
          {t("home.privacy_policy")}
        </a>
        <a
          href="/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackLinkClick("Terms of Service", "Footer Links")}
        >
          {t("home.terms_of_service")}
        </a>
        <a
          href="mailto:contact@deltyo.com"
          onClick={() => trackLinkClick("Contact", "Footer Links")}
        >
          {t("home.contact")}
        </a>
      </div>
    </div>
  );
}
