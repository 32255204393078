import React from "react";
import { useTranslation } from "react-i18next";
import "../config/i18n";

// Assets
import vector from "../assets/image/vector.svg";
import vectorPurple from "../assets/image/vector_purple.svg";
import vectorGreen from "../assets/image/vector_green.svg";

export default function LegalTerms() {
  const { t } = useTranslation();

  const handleBackClick = () => {
    // Logique pour retourner à la page d'accueil
    window.location.href = "/";
  };

  return (
    <div className="legal-terms">
      <img src={vectorPurple} alt="Vector Purple" className="vector" />
      <img src={vectorGreen} alt="Vector Green" className="vector" />
      <img
        className="back-arrow"
        src={vector}
        alt="Back Arrow"
        onClick={handleBackClick}
      />
      <div className="legal-terms-container">
        <div className="company-info">
          <h2 className="company-name">{t('legal_terms.company')}</h2>
          <p><strong>{t('legal_terms.name')}:</strong> {t('legal_terms.name_value')}</p>
          <p><strong>{t('legal_terms.address')}:</strong> {t('legal_terms.address_value')}</p>
          <p><strong>{t('legal_terms.registration')}:</strong> {t('legal_terms.registration_value')}</p>
          <p><strong>{t('legal_terms.vat_number')}:</strong> {t('legal_terms.vat_number_value')}</p>
          <p><strong>{t('legal_terms.capital_stock')}:</strong> {t('legal_terms.capital_stock_value')}</p>
        </div>
        <div className="contact-info">
          <h2 className="contact-title">{t('legal_terms.contact')}</h2>
          <p><strong>{t('legal_terms.phone_number')}:</strong> {t('legal_terms.phone_number_value')}</p>
          <p><strong>{t('legal_terms.email')}:</strong> <a href={`mailto:${t('legal_terms.email_value')}`}>{t('legal_terms.email_value')}</a></p>
          <p><strong>{t('legal_terms.executive_director')}:</strong> {t('legal_terms.executive_director_value')}</p>
          <p><strong>{t('legal_terms.publishing_director')}:</strong> {t('legal_terms.publishing_director_value')}</p>
          <p><strong>{t('legal_terms.hosting')}:</strong> {t('legal_terms.hosting_value')}</p>
        </div>
      </div>
    </div>
  );
}
