import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import des traductions
import HomeTranslationFr from "../pages/Home.translation.fr";
import HomeTranslationEn from "../pages/Home.translation.en";
import LegalTermsTranslationFr from "../pages/LegalTerms.translation.fr";
import LegalTermsTranslationEn from "../pages/LegalTerms.translation.en";

const resources = {
  en: {
    translation: {
      home: HomeTranslationEn,
      legal_terms: LegalTermsTranslationEn,
    },
  },
  fr: {
    translation: {
      home: HomeTranslationFr,
      legal_terms: LegalTermsTranslationFr,
    },
  },
};

i18n
  .use(LanguageDetector) // Utilise le détecteur de langue pour les navigateurs web
  .use(initReactI18next) // Initialise avec react-i18next
  .init({
    resources,
    fallbackLng: "fr", // Langue par défaut si aucune n'est détectée
    interpolation: {
      escapeValue: false, // React protège déjà contre XSS
    },
  });

export default i18n;
